@import "styles/utils.module";
@import "styles/color.module";

.countryImageRootContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flagImageContainer {
  position: relative;
}

.flagImageCircle {
  overflow: hidden;
  border: 0.1px solid $gray300;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionContainer {
  margin-left: 6px;
  color: $typo2;
  font-size: 13px;
  font-weight: 500;
  display: block;
}