@import "styles/color.module";
@import "styles/utils.module";

.container {
  @extend .flexRow;
  @extend .fullWidth;
}


.promotionEndsAtTextWrapper {
  display: flex;
  align-items: center;
  padding: 11px 10px;
  background-color: black;
  height: 32px;
}

.iconPlaceholder {
  width: 16px;
  height: 16px;
}

.promotionEndsAtTextContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.promotionEndsAtText {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: white;
  & > *+* {
    margin-left: 2px;
  }
}

.promotionEndsAtTime {
  color: $brand;
}

.arrowRightIcon {
  & > path {
    fill: white;
  }
}

.infoContainer {
  margin-left: 40px;
}

@include tablet {
  .infoContainer {
    margin-left: 16px;
  }
}

@include mobile {
  .infoContainer {
    margin-left: 0;
    margin-top: 24px;
  }

  .container {
  }
}

@include mobile {
  .container {
    flex-direction: column;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: $typo4;
  display: block;
  line-height: 120%;
  flex: 1;
  height: auto;
  margin-right: 8px;
  //word-break: keep-all;
  word-wrap: break-word;
}

.share {
  width: 24px;
  height: 24px;
}

@include tablet {
  .share {
    width: 20px;
    height: 20px;
  }
}

.shareModal {
  position: absolute;
  height: 56px;
  padding: 0 12px 0 12px;
  background-color: white;
  //border: 1px solid $border;
  right: 0;
  top: 40px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  box-shadow: 0 0 6px rgba(25, 25, 25, 0.15);
  z-index: 50;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 160px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: white;
    border-bottom-width: 20px;
    border-top: 0;
    margin-left: -15px;
    margin-top: -5px;
    //box-shadow: 0 0 5px black ;
  }
}

.shareModal > button {
  background: none;
  border: none;
  padding: 0;
  line-height: 0;
}

.kakaoIcon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  @extend .cursorPointer;
}

.copyIcon {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  @extend .cursorPointer;
}

.closeIcon {
  width: 24px;
  height: 24px;
  @extend .cursorPointer;
}

@include tablet {
  .shareModal {
    height: 48px;
    top: 36px;
    padding: 0 12px;

    &:after {
      left: 140px;
    }
  }

  .kakaoIcon {
    height: 32px;
    width: 32px;
    margin-right: 16px;
  }

  .copyIcon {
    width: 32px;
    height: 32px;
  }
}


.titleContainer {
  margin-top: 6px;
  position: relative;
}

.brandWrapper {
  @extend .flexRow;
  align-items: center;
}

.brand {
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $typo3;
  @extend .flexRow;
}

.crossIcon {
  margin: 0 2px;
}

@include tablet {
  .brand {
    font-size: 14px;
  }
}

@include mobile {
  .brand {
    font-size: 13px;
  }
}

.percentage {
  margin-left: 12px;
}

.imageContainer {
  flex: 1;
  position: relative;
}

.openContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  justify-content: center;
  align-items: center;
  & > *+* {
    margin-top: 16px;
  }
}

.imageOverlay {
  background: rgba(#000, 0.2);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.openWrapper {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  line-height: 140%;
  font-weight: 700;
  text-align: center;
  color: white;
}

.imageWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.originalPriceContainer {
  margin-top: 20px;
  display: flex;
  & > *+* {
    margin-left: 4px;
  }
}

.priceContainer {
  display: flex;
  align-items: center;
  & > *+* {
    margin-left: 2px;
  }
  margin-top: 8px;
}

.couponDiscountPriceContainer {
  display: flex;
  align-items: center;
  margin-top: 4px;
  & > *+* {
    margin-left: 8px;
  }
}

.couponDiscountPriceWrapper {
  display: flex;
  align-items: center;
  & > *+* {
    margin-left: 2px;
  }
}

.couponDiscountTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $emphasis2;
  padding: 3px 4px;
}

.couponDiscountText {
  font-size: 10px;
  line-height: 100%;
  font-weight: 600;
  color: white;
}

.discountRate {
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $brand;
}

.originalPrice {
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $gray400;
  text-decoration: line-through;
}

.brandColor {
  color: $brand;
}

.price {
  font-size: 22px;
  line-height: 100%;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
}

.couponDiscountPrice {
  @extend .price;
  color: $emphasis;
}

.won {
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
}

.couponDiscountWon {
  @extend .won;
  color: $emphasis;
}

@include tablet {

  .percentage {
    margin-left: 4px;
  }
}

.priceInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.priceInfoWrapper {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 9px 16px;
}

.downloadButtonText {
  color: white;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
}

.reviewContainer {
  margin-top: 8px;
  @extend .cursorPointer;
  line-height: 0;
  display: flex;
  align-items: center;
}

.reviewCount {
  color: $gray400;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  vertical-align: middle;
  margin-left: 4px;
}

.writeReviewText {
  text-decoration: underline;
}

.firstReviewEventContainer {
  background-color: $brand;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 5px 10px;
  margin-left: 8px;
}

.rightArrow {
  vertical-align: middle;
  margin-left: 4px;
}

@include tablet {

  .rightArrow {
    margin-left: 2px;
  }
}

.sellCountContainer {
  display: inline-block;
  font-weight: 500;
  color: white;
  line-height: 0;
}

.sellCountWrapper {
  line-height: 0;
  display: block;
  margin-top: 8px;
  margin-bottom: 24px;
}

.sellCountTitle {
  font-size: 14px;
  line-height: 100%;
  display: inline-block;
  font-weight: 500;
  color: $typo2;
  margin-left: 8px;
}

@include tablet {
  .sellCountTitle {
    font-size: 13px;
    margin-left: 4px;
  }
}

.sellCountBody {
  font-size: 14px;
  font-weight: bold;
  line-height: 100%;
  display: inline-block;
  margin-left: 8px;
}

.border {
  background-color: $border;
  height: 1px;
}

@include tablet {
  .border {
    height: 1px;
  }
}

.infoSection {
  display: flex;
  flex-direction: column;
  & > *+* {
    margin-top: 12px;
  }
  padding: 20px 0;
}

.infoRow {
  line-height: 0;
  @extend .flexRow;
}

.infoTitle {
  display: inline-block;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $typo2;
  width: 82px;
}

.infoBody {
  display: inline-block;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  color: $typo3;
  flex: 1;
}

.infoBodyEmphasis {
  font-weight: 700;
}

.infoGlobalTitle {
  font-size: 14px;
  width: 100px !important;
}

@include tablet {
  .infoTitle {
    font-size: 14px;
    width: 82px;
  }

  .infoBody {
    font-size: 14px;
  }
}

.hemekoChartTitleContainer {
  padding: 24px 0 16px 0;
  line-height: 0;
}

@include mobile {
  .hemekoChartTitleContainer {
    padding: 24px 0 12px 0;
  }
}

.chartIcon {
  vertical-align: middle;
}

.hemekoChartTitle {
  font-size: 20px;
  line-height: 100%;
  display: inline-block;
  font-weight: bold;
  color: $brand;
  vertical-align: middle;
  margin-left: 4px;
}

@include tablet {
  .chartIcon {
    width: 20px;
    height: 20px;
  }

  .hemekoChartTitle {
    font-size: 16px;
  }
}

@include mobile {
  .hemekoChartTitle {
    margin-left: 4px;
  }
}

.optionSelector {
  padding: 24px 0 40px 0;
}

.myPriceInfoContainer {
  padding: 0 20px;
  border: 1px solid $border;
  margin-top: 12px;
  @extend .cursorPointer;
}

.myPriceInfoTotalPriceContainer {
  padding: 15px 0;
  @extend .flexRow;
  @extend .justifyContentSpaceBetween;
}

.myPriceInfoTotalPriceKey {
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: $typo3;
  margin-right: 2px;
}

.myPriceInfoTotalPriceValue {
  font-weight: bold;
  line-height: 100%;
  color: $brand;
  font-size: 16px;
}

.myPriceInfoTotalPriceArrowIcon {
  height: 16px;
  width: 16px;
  margin-left: 4px;

  & path {
    fill: #757575;
  }
}

.myPriceDiscountContainer {
  padding: 15px 0;
  border-top: 1px solid $border;
}

.myPriceDiscountKey {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.myPriceDiscountValue {
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.freeDeliveryInfo {
  background-color: $gray900;
  padding: 10px 16px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  margin-top: 16px;
}

.freeDeliveryInfoMyPrice {
  margin-top: 10px;
}

.freeDeliveryInfoText {
  line-height: 100%;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.freeDeliveryInfoTextEmph {
  color: $brand;
}

.bestReviewText {
  font-size: 18px;
  font-weight: 700;
  line-height: 100%;
  color: $gray800;
  margin-top: 20px;
  padding-left: 16px;
}

.bestReviewSection {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 16px 0 20px 0;
}

.productImageSwiperHorizontalClass {
  bottom: 12px !important;
  line-height: 0 !important;
}

.productImageSwiperBulletClass {
  border-radius: 9999px;
  background-color: $lightBackground !important;
  //background-color: green !important;
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 0;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.productImageSwiperBulletActiveClass {
  border-radius: 9999px;
  background-color: $brand !important;
  //background-color: green !important;
  display: inline-block;
  width: 6px;
  height: 6px;
  line-height: 0;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
}

.sellershopButtonText {
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  color: white;
  padding: 0 6px;
}

.adminHashButton {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  background-color: white;
  color: $brand;
  border: 1px solid $brand;
  padding: 8px 8px;
  cursor: pointer;
  width: 100%;
  font-size: 12px;
  line-height: 100%;
  font-weight: 600;

  box-shadow: 1px 1px 1px 1px rgba(25, 25, 25, 0.15);
}

.floatingButtonContainer {
  position: absolute;
  z-index: 10;
  top: 16px;
  right: 16px;

  & > *+* {
    margin-top: 10px;
  }
}

.colorCodePickerContainer {
  padding: 16px 0 15px 0;
  border-bottom: 1px solid $border;
}

.colorPickerSlide {
  width: 38px !important;
}

.colorPickerSlideContainer {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
}

.colorPickerSlideSubcontainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1px;
  z-index: 1;
}

.colorPickerSlideContent {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.colorPickerSlideActiveIndicator {
  position: absolute;
  inset: -1px;
  background-color: black;
  border-radius: 5px;
}

.colorCodePickerDescription {
  font-weight: 500;
  line-height: 100%;
  font-size: 13px;
  color: $gray800;
  padding: 0 16px;
  margin-bottom: 11px;
}
