@import "styles/utils.module";

.button {
  width: 97px;
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
}


.couponInformationRow {
  @extend .flexRow;
  @extend .alignItemsCenter;
  background-color: $gray100;
  justify-content: space-between;
  margin-top: 8px;
  height: 34px;
  text-align: center;
  vertical-align: middle;
  padding: 0 16px;
}

.couponInformationDescription {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $typo3;
}


.couponRowRemoveText {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  color: $typo1;
}
