@import "styles/color.module";
@import "styles/utils.module";

.button {
  width: 97px;
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
}

.input {
  flex: 1;
  height: 44px;
  font-size: 15px;
  vertical-align: middle;
  border: 1px solid $tableBorder;
  padding-left: 20px;
  color: $typo4;
}

.input::placeholder {
  color: $typo1;
}

.input:focus {
  border-color: $brand;
}

.input:disabled {
  background-color: $gray100;
}
